<template>
    <CContainer class="d-flex align-items-center" style="height: 75vh !important">
        <CRow class="w-100 justify-content-center">
            <CCol md="6">
                <div class="w-100">
                    <div class="clearfix">
                    <h1 class="float-left display-3 mr-4">
                        <CIcon
                        style="width: 3rem; height: 4rem; color: #2E2860;"
                        :content="$options.freeSet.cilMoodVeryGood"
                        />
                    </h1>
                    <h4 class="pt-3">Bienvenido {{ nombreUsuario }} a Gesto.</h4>
                    <p class="text-muted">                     
                        Esperamos que tengas un buen día, recuerda cualquier problema con la plataforma no dudes en contactar a "soporte@vapp.cl".           
                    </p>
                    </div>
                    <!--<CInput
                    class="mb-3"
                    placeholder="What are you looking for?">
                    <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
                    <template #append>
                    <CButton color="info">Search</CButton>
                    </template>
                </CInput>-->
                </div>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import { freeSet } from "@coreui/icons";
export default {
  name: "PrincipalUsuario",
  freeSet,
  data() {
    return {
      nombreUsuario: (JSON.parse(this.$cookie.get("userLogginToken")).tipoUsuario == "trabajador")? JSON.parse(this.$cookie.get("userLogginToken")).nombreTrabajador.split(" ")[0] : JSON.parse(this.$cookie.get("userLogginToken")).nombreUsuario,
    };
  },
};
</script>
